import crypto from 'crypto'
import JsEncrypt from 'jsencrypt/bin/jsencrypt'

let jse = new JsEncrypt()
jse.setPublicKey(
  `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCISLP98M/56HexX/9FDM8iuIEQozy6kn2JMcbZS5/BhJ+U4PZIChJfggYlWnd8NWn4BYr2kxxyO8Qgvc8rpRZCkN0OSLqLgZGmNvoSlDw80UXq90ZsVHDTOHuSFHw8Bv//B4evUNJBB8g9tpVxr6P5EJ6FMoR/kY2dVFQCQM4+5QIDAQAB`
)
// rsa加密
// 不可加密数字，否则解密为空
function rsa(text) {
  return jse.encrypt(text)
}

// md5加密
function md5(str) {
  let md5 = crypto.createHash('md5')
  md5.update(str)
  let md5Result = md5.digest('hex')
  return md5Result
}

// 设置sessionStorage
function setSessionStorage(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value))
}
// 获取sessionStorage
function getSessionStorage(key) {
  var result = ''
  try {
    result = JSON.parse(sessionStorage.getItem(key))
  } catch (err) {
    console.log(err)
  }
  return result
}
// 删除sessionStorage
function removeSessionStorage(key) {
  window.sessionStorage.removeItem(key)
}

function clearSessionStorage() {
  window.sessionStorage.clear()
}

export { setSessionStorage, getSessionStorage, removeSessionStorage, clearSessionStorage }
