<template>
  <div id="ib-accounts">
    <div class="wrapper-main">
      <div class="wrapper-content">
        <AccountNumber></AccountNumber>
        <div class="ib-accounts-content">
          <div class="select">
            <p>{{ $t('ibAccounts.subIbs') }}</p>
            <el-cascader
              v-if="subIbAccountList != undefined && subIbAccountList.length > 0"
              ref="subIbAccountCascader"
              :props="{
                expandTrigger: 'click',
                checkStrictly: true,
                lazy: true,
                // https://github.com/ElemeFE/element/issues/16967
                // Use lazyLoad instead of changing subIbAccountList
                // the solution in github by changing key will causes panel keep refreshing
                lazyLoad(node, resolve) {
                  if (!node.data.leaf && !node.loaded) {
                    getIbAccounts(userId, getAccountFromLabel(node.label), subIbUrl).then(resp => {
                      if (resp.data.code === 0) {
                        node.loaded = true;
                        if (resp.data.data.length > 0) {
                          const nodes = buildCascaderOption(resp.data.data);
                          resolve(nodes);
                        } else {
                          node.data.leaf = true;
                          // Hide the 'no data' panel after resolve
                          $nextTick(() => {
                            cascaderMenuClick(node);
                          });
                          resolve();
                        }
                      }
                    });
                  } else {
                    resolve();
                  }
                }
              }"
              :options="subIbAccountList"
              v-model="subIbAccount"
              @change="handleChange"
              filterable
              :show-all-levels="false"
              popper-class="ib-account-dropdown"
            >
              <template slot-scope="{ node, data }">
                <div :ref="node.uid" @click="cascaderClick(node, data)">
                  {{ data.label }}
                </div>
              </template>
            </el-cascader>
            <p v-else>&nbsp;{{ $t('common.keys.NONE') }}</p>
          </div>
          <div class="card">
            <div class="table-content">
              <ul class="tab-demo">
                <li
                  :class="{ active: selectedCard == 'activatedAccounts' }"
                  @click="selectedCard = 'activatedAccounts'"
                  data-testid="activatedAccounts"
                >
                  {{ $t('unfundedAcc.allActivationAccs') }}
                </li>
                <li
                  :class="{ active: selectedCard == 'neverFunded' }"
                  @click="selectedCard = 'neverFunded'"
                  data-testid="neverFunded"
                >
                  {{ $t('unfundedAcc.neverFundedAccs') }}
                </li>
                <li
                  :class="{ active: selectedCard == 'zeroBalance' }"
                  @click="selectedCard = 'zeroBalance'"
                  data-testid="zeroBalance"
                >
                  {{ $t('unfundedAcc.prevFundedZero') }}
                </li>
              </ul>

              <ul class="table-box-item">
                <li :class="{ active: selectedCard == 'activatedAccounts' }">
                  <div class="table-box">
                    <el-table
                      ref="traderTable"
                      :data="
                        traderTableData | columnFilter(filters, setFilteredTotal) | tableDataPageFilter(pageNo, size)
                      "
                      style="width: 100%;"
                      @sort-change="sortChange"
                      @filter-change="handleFilterChange"
                      :row-key="traderTableData != undefined ? traderTableData.accountNmber : ' '"
                    >
                      <el-table-column prop="registerDate" :label="$t('common.keys.REGISTRATION')" sortable="custom" min-width="230">
                        <template slot-scope="traderTableScope">
                          {{ traderTableScope.row.registerDate | date('DD/MM/YYYY') }}
                        </template>
                      </el-table-column>
                      <el-table-column prop="date" :label="$t('common.keys.DATE')" sortable="custom" min-width="210">
                        <template slot-scope="traderTableScope">
                          {{ traderTableScope.row.date | date('DD/MM/YYYY') }}
                        </template>
                      </el-table-column>
                      <el-table-column prop="accountNmber" :label="$t('common.keys.ACCNUM')" min-width="180">
                        <template slot-scope="traderTableScope">
                          <u>
                            <a
                              @click.stop.prevent="showDetail(traderTableScope.row.accountNmber, traderTableScope.row.platform)"
                              data-testid="showDetail"
                            >
                              {{ traderTableScope.row.accountNmber }}
                            </a>
                          </u>
                        </template></el-table-column
                      >
                      <el-table-column prop="name" :label="$t('common.keys.NAME')" min-width="100">
                        <template slot-scope="traderTableScope">
                          <span v-html="traderTableScope.row.name" />
                        </template>
                      </el-table-column>
                      <el-table-column prop="email" :label="$t('common.keys.EMAIL')" min-width="200"></el-table-column>
                      <el-table-column
                        prop="accountType"
                        :label="$t('common.keys.ACCTYPE')"
                        :filters="accountTypeOptions"
                        column-key="accountType"
                        min-width="160"
                      >
                        <template slot-scope="traderTableScope">
                          {{ $config.accountTypeMaps[traderTableScope.row.accountType] }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="platform"
                        :label="$t('common.keys.PLATFORM')"
                        :filters="platformOptions"
                        column-key="platform"
                        min-width="150"
                      >
                        <template slot-scope="traderTableScope">{{ 'MT' + (traderTableScope.row.platform == 4 ? '4' : traderTableScope.row.accountType == 99?"S":'5') }}</template>
                      </el-table-column>
                      <el-table-column
                        prop="baseCurrency"
                        :label="$t('common.keys.BASECURRENCY')"
                        :filters="currencyOptions"
                        column-key="baseCurrency"
                        min-width="170"
                      ></el-table-column>
                      <el-table-column
                        prop="accountBalance"
                        :label="$t('common.keys.BALANCE')"
                        sortable="custom"
                        min-width="140"
                        :sort-orders="['ascending', 'descending']"
                      >
                        <template slot-scope="traderTableScope">
                          {{ traderTableScope.row.accountBalance | currency('') }}
                        </template>
                      </el-table-column>

                      <!-- Profit Lost -->
                      <el-table-column
                        prop="profit"
                        :label="$t('common.keys.PROFITLOST')"
                        sortable="custom"
                        min-width="180"
                        :sort-orders="['ascending', 'descending']"
                      >
                        <template slot-scope="traderTableScope">
                          {{ traderTableScope.row.profit | currency('') }}
                        </template>
                      </el-table-column>
 
                      <el-table-column
                        prop="firstDepositDate"
                        :label="$t('common.keys.FTD')"
                        sortable="custom"
                        min-width="200"
                      >
                        <template slot-scope="traderTableScope"  v-if="traderTableScope.row.firstDepositDate">
                          {{ traderTableScope.row.firstDepositDate | date('DD/MM/YYYY') }}
                        </template>
                      </el-table-column>

                      <el-table-column
                        prop="firstTransferInDate"
                        :label="$t('common.keys.FIRST_TRANS_IN')"
                        sortable="custom"
                        min-width="290"
                      >
                        <template slot-scope="traderTableScope"  v-if="traderTableScope.row.firstTransferInDate">
                          {{ traderTableScope.row.firstTransferInDate | date('DD/MM/YYYY') }}
                        </template>
                       
                      </el-table-column>

                      <el-table-column
                        prop="participatedCampaigns"
                        :label="$t('common.keys.CURRENTCAMPAIGN')"
                        :filters="currentCampaign"
                        column-key="participatedCampaigns"
                        min-width="300"
                      >
                      <template slot-scope="traderTableScope" v-if="traderTableScope.row.participatedCampaigns && traderTableScope.row.participatedCampaigns.length > 0">
                        <span v-for="(item, i) in traderTableScope.row.participatedCampaigns" :key="i">
                          {{ item.campaignName }} {{ item.blacklisted ? `(${$t('common.keys.blacklisted')})`: '' }}{{ (i+1)!==traderTableScope.row.participatedCampaigns.length ? ',' : '' }}
                          <br/>
                        </span>
                      </template>
                      </el-table-column>

                      <!-- Margin Level -->
                      <el-table-column
                        prop="marginLevel"
                        :label="$t('common.keys.MARGINLVL') + '(%)'"
                        sortable="custom"
                        min-width="200"
                        :sort-orders="['ascending', 'descending']"
                      >
                        <template slot-scope="traderTableScope">
                          {{ traderTableScope.row.marginLevel | currency('') }}
                        </template>
                      </el-table-column>

                      <!-- Account Equity -->
                      <el-table-column
                        prop="equity"
                        :label="$t('common.keys.ACCEQUITY')"
                        sortable="custom"
                        min-width="240"
                        :sort-orders="['ascending', 'descending']"
                      >
                        <template slot-scope="traderTableScope">
                          {{ traderTableScope.row.equity | currency('') }}
                        </template>
                      </el-table-column>

                      <!-- Credit -->
                      <el-table-column
                        prop="credit"
                        :label="$t('common.keys.CREDIT')"
                        sortable="custom"
                        min-width="180"
                        :sort-orders="['ascending', 'descending']"
                      >
                        <template slot-scope="traderTableScope">
                          {{ traderTableScope.row.credit | currency('') }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <div class="page-box clearfix">
                    <el-pagination
                      :background="true"
                      :page-size="25"
                      layout="total, sizes, prev, pager, next"
                      :page-sizes="[25, 50, 100]"
                      :total="filteredTotal"
                      @current-change="handleCurrentChange"
                      @size-change="handleSizeChange"
                      :current-page.sync="pageNo"
                    ></el-pagination>
                  </div>
                </li>
                <UnfundedAccounts
                  :selectedCard="selectedCard"
                  :unfundedAccountsObject="unfundedAccountsObject"
                  @showDetail="showDetail"
                ></UnfundedAccounts>
              </ul>
              <!-- 分页 -->
            </div>
          </div>
        </div>
      </div>
      <TradingList :tradingListVisible.sync="tradingListVisible" :accountNumber="accountDetails" :platform="accountPlatform"></TradingList>
    </div>
  </div>
</template>

<script>
import AccountNumber from '@/components/form/AccountNumber';
import UnfundedAccounts from '@/views/UnfundedAccounts';
import TradingList from '@/views/TradingList';
import { getSessionStorage, setSessionStorage, removeSessionStorage } from '@/util';
import { apiIbAccounts } from '@/resource';

export default {
  name: 'IbAccount',
  components: { AccountNumber, TradingList, UnfundedAccounts },
  data() {
    return {
      none: this.$t('common.keys.NONE'),
      all: this.$t('common.keys.ALL'),
      userId: this.$store.state.common.CUID,
      account: '',
      subIbAccountList: [],
      subIbAccount: [],
      selectedIbAccount: '',
      traderUrl: 'retail_clients',
      subIbUrl: 'sub_ibs/customer',
      filteredTotal: 0,
      traderTableData: [],
      platformOptions: [],
      currencyOptions: [],
      currentCampaign: [],
      pageNo: 1,
      size: 25,
      filters: {},
      accountTypeOptions: [],
      selectedCard: 'activatedAccounts',
      unfundedAccountsObject: { IBAccount: '', subIBAccount: 'none' },
      tradingListVisible: false,
      accountDetails: 0,
      accountPlatform: 0,
    };
  },
  watch: {
    ibAccount: {
      handler(newValue) {
        this.$nextTick(() => {
          this.unfundedAccountsObject.IBAccount = newValue;
          if (newValue) this.ibAccOnChange(newValue);
        });
      },
      immediate: true
    },
    traderTableData(newData) {
      this.buildFilterOptions();
    },
    selectedIbAccount(newAccount) {
      this.removeSortLabels();
      //clear table filters
      this.filters = {};
      this.$refs.traderTable.clearFilter();
      // console.log("value in selected ib account: ", newAccount);
      if (newAccount === this.all) {
        this.traderTableData = getSessionStorage('TTD' + newAccount + this.ibAccount);
        if (this.traderTableData) {
          return;
        }
        this.getIbAccounts(this.userId, this.ibAccount, 'all_clients').then(resp => {
          if (resp.data.code === 0) {
            this.traderTableData = resp.data.data;
            setSessionStorage('TTD' + newAccount + this.ibAccount, this.traderTableData);
          }
        });
        return;
      }
      if (newAccount === this.none) {
        this.traderTableData = getSessionStorage('TTD' + this.ibAccount);
        return;
      }
      this.traderTableData = getSessionStorage('TTD' + newAccount);
      if (this.traderTableData) {
        return;
      }
      this.getIbAccounts(this.userId, newAccount, this.traderUrl).then(resp => {
        if (resp.data.code === 0) {
          this.traderTableData = resp.data.data;
          setSessionStorage('TTD' + newAccount, this.traderTableData);
        }
      });
    }
  },
  methods: {
    ibAccOnChange(newValue) {
      //clear table filters
      this.filters = {};
      this.$refs.traderTable.clearFilter();

      this.selectedIbAccount = newValue;
      this.subIbAccount = ['none'];
      this.unfundedAccountsObject.subIBAccount = 'none';
      this.subIbAccountList = getSessionStorage('SUBIB' + newValue);
      //return if cached result is found and the result has child menu
      if (this.subIbAccountList && this.subIbAccountList.some(e => e.hasOwnProperty('children'))) {
        return;
      }
      //retrieve sub ib accounts under the current ib account
      console.log(this.subIbUrl,'22222')
      this.getIbAccounts(this.userId, newValue, this.subIbUrl).then(resp => {
        if (resp.data.code === 0 && resp.data.data.length > 0) {
          this.subIbAccountList = this.buildCascaderOption(resp.data.data);
          this.subIbAccountList.push({ value: 'all', label: this.all, leaf: true });
          this.subIbAccountList.unshift({ value: 'none', label: this.none, leaf: true });
          this.$nextTick(() => setSessionStorage('SUBIB' + newValue, this.subIbAccountList));
        }
      });
    },
    handleFilterChange(filters) {
      let [key] = Object.keys(filters);
      let flag = true; //check if it is a same filter. no update for same filters
      if (this.filters[key]) {
        if (filters[key].length === this.filters[key].length) {
          for (let i = 0, end = filters[key].length; i < end; i++) {
            if (filters[key][i] != this.filters[key][i]) {
              flag = false;
              break;
            }
          }
          if (flag) {
            return;
          }
        }
      }
      this.$set(this.filters, key, filters[key]);
    },
    // 排序
    sortChange(column) {
      if (column.column == null) {
        let key = 'TTD' + this.selectedIbAccount;
        if (this.selectedIbAccount === this.all) {
          key += this.ibAccount;
        }
        this.traderTableData = getSessionStorage(key);
        return;
      }
      this.traderTableData = getSessionStorage(
        'TTD' + this.ibAccount + this.selectedIbAccount + column.prop + column.order
      );
      if (this.traderTableData) {
        return;
      }
      let sortUrl = 'sort_clients';
      let account = this.selectedIbAccount;
      if (this.selectedIbAccount === this.all) {
        sortUrl = 'sort_all_clients';
        account = this.ibAccount;
      }
      this.getSortedTable(this.userId, account, column.prop, column.order === 'ascending' ? 1 : 0, sortUrl).then(
        resp => {
          if (resp.data.code === 0) {
            this.traderTableData = resp.data.data;
            setSessionStorage(
              'TTD' + this.ibAccount + this.selectedIbAccount + column.prop + column.order,
              this.traderTableData
            );
          }
        }
      );
    },
    removeSortLabels() {
      const sortElements = $('.el-table__header .is-leaf.is-sortable');
      for (let i = 0, end = sortElements.length; i < end; i++) {
        if (sortElements[i].classList.contains('descending')) {
          sortElements[i].click();
        } else if (sortElements[i].classList.contains('ascending')) {
          sortElements[i].click();
          sortElements[i].click();
        }
      }
    },
    handleCurrentChange(val) {
      this.pageNo = val;
    },
    handleSizeChange(size) {
      this.size = size;
    },
    setFilteredTotal(total) {
      this.filteredTotal = total;
    },
    getAccountFromLabel(label) {
      let nameAndAccount;

      let nameAccountAndType = label.split(' - ');
      nameAndAccount = nameAccountAndType[0].split(' ');

      return nameAndAccount[nameAndAccount.length - 1];
    },
    buildCascaderOption(accountList) {
      let resultOption = [];
      accountList.forEach((e, i) => {
        let label = e.name + ' ' + e.account + ' - ' + this.$config.accountTypeMaps[e.accType];

        // Issue: value = 0 (i)
        // Impact: el-cascader's getNodeByValue return null
        // Due to if(value) is false
        resultOption.push({
          value: e.account,
          label: label,
          leaf: false
        });
      });
      return resultOption;
    },
    getIbAccounts(userId, account, url) {
      return apiIbAccounts(url, {
        userId: userId,
        account: account
      });
    },
    getSortedTable(userId, account, sortingField, sortBy, url) {
      return apiIbAccounts(url, {
        userId: userId,
        account: account,
        sortingField: sortingField,
        sortBy: sortBy
      });
    },
    // 下拉菜单几级联动
    cascaderMenuClick(node) {
      this.$refs[node.uid]?.closest('.el-cascader-node')?.click?.();
    },
    cascaderClick(node) {
      this.$refs[node.uid]
        ?.closest('.el-cascader-node')
        ?.querySelector('.el-radio .el-radio__input .el-radio__original')
        ?.click();
    },
    handleChange() {
      // For filter value
      this.$nextTick(() => {
        const selectedNode = this.$refs.subIbAccountCascader?.getCheckedNodes?.()?.[0];
        // https://github.com/ElemeFE/element/issues/16967
        // Fix error message when lazyLoading running and click parent node too fast
        // if (this.$refs.subIbAccountCascader?.panel) {
        //   this.$refs.subIbAccountCascader.panel.activePath = [];
        // }

        if (selectedNode) {
          this.selectedIbAccount = this.getAccountFromLabel(selectedNode.data.label);
          switch (this.selectedIbAccount) {
            case this.all:
              this.unfundedAccountsObject.subIBAccount = 'all';
              break;
            case this.none:
              this.unfundedAccountsObject.subIBAccount = 'none';
              break;
            default:
              this.unfundedAccountsObject.subIBAccount = this.selectedIbAccount;
              break;
          }
        }
      });
    },
    buildFilterOptions() {
      this.accountTypeOptions = [];
      this.platformOptions = [];
      this.currencyOptions = [];
      this.currentCampaign = [];
      let accountTypeSet = new Set();
      let platformSet = new Set();
      let currencySet = new Set();
      let campaignSet = new Set();
      if (this.traderTableData == undefined) {
        return;
      }
      this.traderTableData.forEach(e => {
        accountTypeSet.add(e.accountType);
        platformSet.add(e.platform);
        currencySet.add(e.baseCurrency);
        if(e.participatedCampaigns && e.participatedCampaigns.length >0) campaignSet.add(e.participatedCampaigns.map(item => item.campaignName));
      });
      // 二维数组换成一维数组 再去重
      const campaignSetArray = [...new Set([].concat(...campaignSet))];
      accountTypeSet.forEach(e => {
        this.accountTypeOptions.push({
          text: this.$config.accountTypeMaps[e],
          value: e
        });
      });
      platformSet.forEach(e => {
        this.platformOptions.push({
          text: 'MT' + e,
          value: e
        });
      });
      currencySet.forEach(e => {
        this.currencyOptions.push({
          text: e,
          value: e
        });
      });
      campaignSetArray.forEach(e => {
        this.currentCampaign.push({
          text: e,
          value: e
        });
      });
    },
    clearSubibCache() {
      Object.keys(sessionStorage).forEach(e => {
        if (/^(SUBIB|TTD){1}[\s\S]*$/.test(e)) {
          removeSessionStorage(e);
        }
      });
    },
    showDetail(account, platform) {
      this.accountDetails = account;
      this.tradingListVisible = true;
      this.accountPlatform = platform || 0;
    }
  },
  created() {
    this.clearSubibCache();
  },
  filters: {
    columnFilter(tableData, filters, setFilteredTotal) {
      if (tableData == undefined) {
        return;
      }
      let keys = Object.keys(filters);
      if (keys.length > 0) {
        tableData = tableData.filter(element => {
          let flag = true;
          for (let i = 0, end = keys.length; i < end; i++) {
            if (filters[keys[i]] != undefined && filters[keys[i]].length > 0) {
              // 如果是活动数组的话 并且有数据的情况下
              if(keys[i]==='participatedCampaigns' && element[keys[i]] && element[keys[i]].length > 0){
                // 判断数组中是否至少存在一个满足条件的元素在筛选列表中
                if(!element[keys[i]].some((j) => filters[keys[i]].includes(j.campaignName))){
                  flag = false;
                  break;
                }
              }else if (filters[keys[i]].indexOf(element[keys[i]]) < 0) {
                flag = false;
                break;
              }
            }
          }
          return flag;
        });
      }
      if (tableData) {
        setFilteredTotal(tableData.length);
      }
      return tableData;
    },
    tableDataPageFilter(tableData, pageNo, size) {
      if (tableData == undefined) {
        return;
      }
      let start = (pageNo - 1) * size;
      let end = pageNo * size;
      return tableData.slice(start, end);
    }
  },
  computed: {
    ibAccount() {
      return this.$store.state.behavior.AID;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/leads.scss';
@import '@/assets/css/components/pagination.scss';
@import '@/assets/css/pages/ibAccounts.scss';
</style>

<style lang="scss">
.ib-account-dropdown {
  .el-radio {
    display: none;
  }
}


</style>
