<template>
  <el-dialog
    :visible="visible"
    top="0"
    @close="close"
    :append-to-body="needToBody"
    :fullscreen="(needFullscreen && fullscreen) || needFullscreen"
  >
    <div slot="title">
      <slot name="header"></slot>
      <em class="el-icon-close close_image" @click="close"></em>
    </div>
    <div class="dialog_body">
      <slot></slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'home_dialog',
  props: {
    visible: Boolean,
    needFullscreen: Boolean,
    needToBody: { type: Boolean, default: true }
  },
  data() {
    return {
      fullscreen: false
    };
  },
  mounted() {
    this.getMedia();
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
    getMedia() {
      const matches = window.matchMedia('(max-width: 768px)').matches;
      if (matches) this.fullscreen = true;
      else this.fullscreen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/dialog/vDialogTable.scss';
</style>
